<script type="text/javascript"></script>

<template>  

	<div>
			
		<TopBar :columnnumber = "2" hidedropdown simple>
			<template v-slot:title>
				Portfolios
			</template>
			
		</TopBar>


<b-modal @shown="portfoliomodalshown" header-class="align-items-center" size="md" v-model="shownewportfoliomodal" id="shownewportfoliomodal-prompt" title="Settings" hide-footer sidebar-class="sidebarglobal" scrollable >

		<template v-slot:modal-header>
		<h3 class="modal-title">Portfolio created</h3>
		<a class = "close-modal dialog-close-bustaketton mr-2">
		<i class="fal fa-times fa-lg" @click.stop.prevent="shownewportfoliomodal=false"></i>
		</a>
		</template>

		<div>

		<p>You have created a new project portfolio. You can now add projects (boards) to your portfolio.</p>

		<p>From the project portfolio settings menu, you can:</p>

		<ul>
		<li>Edit, add or remove project phases</li>
		<li>Add new project portfolio parameters</li>
		</ul>

		<p>Project portfolio parameters are values or information the project office assigns to each project, such as project categorization, a project budget, a list of project approvals, or baseline documents.</p> 

		<p>We have created a project office team. Add your portfolio colleagues to the team. Members will be able to administrate the project portfolio.</p>

		<p>
		<img src="/static/img/portfoliosample.png" style="width: 90%;" class="text-center">
		</p>

		<p>
		For more help and guides on how to use Flowe project portfolios:

		<ul>
		<li><a href="/help/2527461377" target="_blank">Introduction to project portfolios <i class="fa-solid fa-arrow-up-right-from-square"></i></a></li>
		<li><a href="/help/2528083970" target="_blank">Managing project portfolio parameters <i class="fa-solid fa-arrow-up-right-from-square"></i></a></li>
		<li><a href="/help/2527789057" target="_blank">Adding projects to a portfolio <i class="fa-solid fa-arrow-up-right-from-square"></i></a></li>
		</ul>

		</p>
			
		</div>

	</b-modal>


			<main class="main-wrapper cockpitview" style= "margin-top:21px;">

	<div class="cockpits">

		<h3 class="mt-2" v-if="1==2">My portfolios</h3> 

		<div class="dashboarditemwrapper2">

			<div class="d-flex align-items-start flex-wrap">

				<template v-for="(item,key) in portfolios">
				
				<router-link v-if="( item.active && item.myrole !='' ) || ( !item.active && item.myrole != 'Viewer' && item.myrole != '') " :to="'/'+orgkey+'/portfolio/'+item._id+'/detail'"  :key="key" class="card-block mr-2">
					<div class="card dashboarditem" :class="{'inactive':!item.active}">

						<div class="d-flex flex-row">
							<div class="accent" :class="{'inactive':!item.active}"></div>

							<div class="align-self-center w-100"> 

								<div class="align-self-center  w-auto card-title">
									<span style="word-break: break-word;">{{ item.name }}</span>
								</div>
								<div class="activity row justify-content-md-center">
									<p data-toggle="tooltip" data-placement="bottom" title="Projects" class="col px-2">
										<span><i class="far fa-border-all mr-2" aria-hidden="true"></i>{{ item.numboards }}</span>
									</p>
								</div>
							</div>

						</div>

					</div>
				</router-link>
				</template>

				<div class="card dashboarditem p-3" >

					<a v-if="!initnewportfolio" href="#" @click.stop.prevent="initnewportfolio=true">
					<div   class="text-center m-auto"><i class="fa fa-plus-circle" style="font-size: 6rem;" aria-hidden="true"></i><br><span style="font-size: 15px;">Create new portfolio</span></div></a>

				<form v-else class="" onsubmit="return false">
		        	<br>
		        	<input type="text" class="form-control" placeholder="Portfolio name" v-model="name" id="cockpitfromdashboardname" ref="newportfolioname" >
		        	<span class="text-danger" v-if="error!=''">&nbsp;{{error}}</span>

		        	<div class="mt-1 text-right">
		        	<button type="button" class="btn btn-secondary mr-2" @click="initnewportfolio=false">Cancel</button>

		        	<button :disabled="name.length<3" type="submit" class="btn btn-primary" @click="addnewportfolio()">Create new portfolio</button>
		        </div>
		        </form>

				
				</div>

			</div>

			

		

		</div>

	</div>

</main></div>
</template> 


<style type="text/css">
	
	/*.portfolio{
		width: 250px;
		height: 125px;
	}
*/
</style>

<script>

	import {TopBar} from '@/components/topbar'

	import Portfolioapi from '@/services/api/portfolio'

	import uuid from 'uuid'

	//const uuidv4 = require("uuid/v4")

	import { v4 as uuidv4 } from 'uuid'

	import Dashboardapi from '@/services/api/dashboard'

	export default{
		name:'portfolio',
		props:{
		},

		data(){
			return{
				portfolios:[],
				name:"",
				error:"",
				find:0,
				initnewportfolio:false,
				shownewportfoliomodal:false,
				newportfoliomessagebody:"",
				newportfoliotitle:""
			}
		},

		 mounted() {

		 	let self = this


		 	document.title = 'Portfolio | Flowe'

		 	this.getallportfolios()

		 	/*Dashboardapi.Loadpage("2528083970").then( function( response ){
		 		if( response.data.page && response.data.page.body ){
		 			self.newportfoliomessagebody = response.data.page.body
		 			self.newportfoliotitle = response.data.page.title
		 		}
		 	})*/

		 	if( this.$route.query.showmodal ){
		 		this.shownewportfoliomodal = true
		 	} 
		 },

		watch:{

			shownewportfoliomodal(e){

				if( e ){
					$('body').addClass('noscroll')
				}else{
					$('.noscroll').removeClass('noscroll')
				}


			},
			
			initnewportfolio(){
				this.$nextTick(() => this.$refs.newportfolioname?.focus())
			}
		},
		methods:{

			portfoliomodalshown(){

				
			},

			/*newportfolioshown(){

				setTimeout( function() {
	 				$( 'a', '#newportfoliomessagebody').attr('target', '_blank');
	 				$( 'a', '#newportfoliomessagebody').append('<i class="ml-2 fa-solid fa-arrow-up-right-from-square"></i>')
	 			}, 300 )

			},*/

			getallportfolios(){

				let self = this

				Portfolioapi.getAll( {orgkey:this.orgkey, token:this.token}).then( function( result ){

					if( result.data.ok && result.data.portfolios ){
						self.portfolios = result.data.portfolios
					}

				})
			},


			addnewportfolio(){

				let self = this

				if( this.name != "" ){

					this.initnewportfolio = false

					Portfolioapi.addnew( { uuid:uuidv4(), name:this.name, context:this.orgkey, t:this.token } ).then( function( result ){
						self.shownewportfoliomodal = true
						self.name = ""
						self.getallportfolios()
					})
				}

				return false

				
			}
		},

		computed:{

			cockpitid(){
        		return this.$route.params.cid
     		},

			orgkey(){
		      return this.$route.params.orgname ? this.$route.params.orgname : null
		    },	

			token(){
				return this.$store.getters.token
			},

      		cockpit(){
				return this.$store.getters.GetCockpit
			},

		},

		components:{ TopBar }
	}
	</script>