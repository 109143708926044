<script type="text/javascript">
</script>
<template>
<div id="newslettermodalid" :class="{ 'cockpitview':stagesizestate==0, 'cockpitviewnarrow':stagesizestate==1 }">

	<div class="d-flex">
	  <div class="flex-grow-1">
	  	<div id="newsletterviewerstage" class="mr-2" :style="'min-width: 360px;' + ( devisetype=='mobil' ? 'max-width:360px': '' )"> 



				  	<div class="d-flex">	  
					  <div class="p-2"><button @click="doneview(true)" type="button" class="btn btn-secondary" ><i class="fas fa-arrow-left"></i>&nbsp;Back</button></div>
					  <div class="p-2 flex-grow-1" v-if="!newsletter.name">
					  	<b-skeleton width="65%"></b-skeleton> 
					  </div>
					  <div class="p-2 flex-grow-1" v-else>
					  	<h3> {{newsletter.name}} </h3> 
					  </div>
					  <div class="p-2 text-right d-flex flex-nowrap align-self-center">
						  	
						  	<button @click.stop.prevent="exporttoword()" type="button" tabindex="-1" role="button" class="btn btn-secondary">
								Export to Word</button>
						  	<a v-if="devisetype!='mobil' && 1==2" @click.stop.prevent="toggleviewsize()" type="button" tabindex="-1" role="button" class="p-2">
						  		<i style="font-size: 16px" class="far fa-arrows-h" aria-hidden="true"></i><span class="fr-sr-only">Expand editor</span></a>

						</div>
					</div>


		   

			<div class="" :class="{ 'p-3 readingmode' : devisetype!='mobil' }"  id="newslettereditorcard" style="position: relative;">								

			<div v-html="newsletter.contents"></div>


			<div class="comment-widgets">
			<div class="mt-2 text-left">
				<indicators :indicator="newsletter" :activeCockpit="cockpit.isactive" @showcomments="showcomments" @like="Postlike" :feedback="cockpit.isactive == true ? 'readwrite' : 'readonly'" :liketype="'newsletter'" :loading = "loadingindicatordata"></indicators>
			</div>

			<a :href="'#addcomments_'+newsletter._id" ref="gotocomments" target="_self">&nbsp;</a>

			<div id="newslettercomments" v-if="showalways">
			<comments v-if="newsletter" :placeholder="'Add a comment to the report...'" :cockpit="cockpit" :activeObjective="true" :activeCockpit="cockpit.isactive" :loggedinuser="loggedinuser" :objectdata="newsletter" @onlike="Postlike" @replycomment="replycomment" @updated="updatepages" @oncommentposted="commentposted" @onupdatecomments="loadComments" :feedback="cockpit.isactive == true ? 'readwrite' : 'readonly'" :commenttype="'newsletter'" :showalways="true" ></comments>
			</div>

			</div>

			</div>


			

		</div>


	  </div>

	</div>

</div>

</template>

<style lang = "scss" scoped>

	.readingmode{
		max-width: 760px;
		margin: auto;
	}
</style>
<script>

	import Cockpitsapi from '@/services/api/cockpits'
	import Newslettersapi from '@/services/api/newsletters'
	import Userapi from '@/services/api/users'

	import {indicators,comments} from '@/components/feedback';

	//import objectivesCard from '@/views/cockpit/components/objectivesCard'
	//import moment from 'moment';
	//import Boardcharts from '@/components/boardcharts/boardcharts.vue'
	//import Riskcharts from '@/components/boardcharts/riskcharts.vue'

	 //import vueselect from '@/views/tools/vueselect'
	
	//import aipanel from '@/components/aipanel'
	//import aipanelinline from '@/components/aipanel/inline.vue'

	import {bus} from '@/main'


	//import AWS from 'aws-sdk'
	//import axios from 'axios'

	import Viewer from 'viewerjs';


	export default{
		name:'reportsviewpage',
		data(){
			let self = this
			return{
				newsletter : {},
				errors: [],
				maxwidth: true,
				loadingindicatordata:{like:false, comments:true},
				exportedwordlocation:"",
				exportingtoword:false,
				cockpit:{},
				showalways: false,
				publisheddone:false,
				devisetype:"",
				stagesizestate:0
			}
	},	
	props:{
		shown:{
			type:Boolean,
			default:()=>false
		},
		viewer:{
			type:Boolean,
			default:()=>false
		},
		cockpitdata:{
			type:Object,
			default:()=>{}
		},
	},
	components:{indicators,comments},
	computed:{

		canexpand(){

			return true
		},
		
		cockpitid(){
        		return this.$route.params.cid
     		},

		token(){
			return this.$store.getters.token
		},
		
		orgkey(){
		      return this.$route.params.orgname ? this.$route.params.orgname : null
		    },

		loggedinuser(){
			return this.$store.getters.user
		}
	},

	mounted(){

		this.isworking = false

		document.vmodal = this

		var self = this	

		this.profile = this.$store.state.user.userInfo

		//this.getWindowWidth()
		this.$nextTick(function() {
			//window.addEventListener("resize", this.getWindowWidth);

			self.opennewsletterforviewing( self.$route.query.nid , function(){


			} )


			self.windowOnresize()
        })

	},

	watch:{

		cockpitdata: function( e ){
			this.cockpit = e
		}
	},

	filters: {			
	},

	created() {
		//window.addEventListener("scroll", this.divscrolling);
		//this.windowinnerHeight = window.innerHeight
		window.addEventListener("resize", this.windowOnresize);
	},
	destroyed() {
		/*window.removeEventListener("resize", this.windowOnresize);*/
	},

	methods:{

		attachviewer( img ){

		let self = this

		this.viewer = new Viewer( img, {
				title : false,
				toolbar: {
				zoomIn: 1,
				zoomOut: 1,
				rotateLeft: 1,
				rotateRight: 1
			},
				movable : true,
				inline: false,
				button: false,
				navbar: false,
			viewed() {
			//viewer.zoomTo(0);
			},
			hidden(){
				self.viewer.destroy()
			},
		}).view(0);

		},

		doneview( ){

				var tourl = "/" + this.orgkey + "/boards/" + this.cockpitid + "/reports"
				this.$router.push( tourl )
		},

		windowOnresize(){

			let self = this

			this.$nextTick(function(){
				self.windowinnerHeight = window.innerHeight


				if( window.innerWidth < 768 ){
			      	self.devisetype= 'mobil'
			      }else if(window.innerWidth < 1024){
			      	self.devisetype = 'tablet'
			      }else{
			      	self.devisetype = 'desktop'
			      }
			})


		},

		toggleviewsize(){

			//let stage = document.getElementById('newsletterviewerstage')
			//stage.style.width = "900px";
			//stage.style.maxWidth = stage.style.maxWidth == "900px" ?  "none" : "900px";
			this.stagesizestate = this.stagesizestate == 0 ? 1 : 0;

		},

		momentdate( indicatordate ){

			return moment(indicatordate).format('YYYY-MM-DD')

		},


		closeallreplyboxes(){

	        for( var i in this.comments ){
	          this.comments[i].writingreply = false
	        }
	      },

		  commentposted( newsletter, comments, commenttype, iid, numcomments ){

		  	//self.$emit('oncommentposted', indicator, response.data.comments, commenttype, iid, response.data.numcomments )

		  	this.newsletter.commentscount = numcomments

		  	if( commenttype == 'newsletter' ){
		  		this.newsletter.comments = comments		  		
		  	}

		  	if( commenttype == 'comment' ){
		  		
		  		for( var i in this.newsletter.comments ){

		  			if( this.newsletter.comments[i]._id == iid ){
		  				this.newsletter.comments[i].comments = comments
		  			}		  			
	        	}
		  	}
		  	  bus.$emit('updatednewslettercommentscount',newsletter._id, numcomments)

		  },

		  Postlike(type, object , action){

		  	var iid = object.ID ? object.ID : object._id

 
	         var self = this
	 
	         Cockpitsapi.Issuealike( type, iid, action, this.token,this.cockpit._id )
	         .then( function(response){

	           if( response.data.ok ){

		            object.likescount = response.data.numlikes
		            object.dislikescount = response.data.numdislikes
		            object.vote = response.data.hasvoted
		            bus.$emit('updatednewsletterlikecount',iid,object.likescount, object.dislikescount)

	           }
	 
	        })
	         .catch(error => console.log(error))
	         .finally( () => {
	 
	               //do everything
	               //self.signin  = { password:"", signinisvalid:true, emailisvalid:true, signinlocked:false, passwordempty:false }
	 
	             })
	 
	       },



		getcomments( newsletterid ){

			var self = this

			Cockpitsapi.Comments( { context:this.orgkey, type:"newsletter", iid:newsletterid, "token": this.token } )
			.then( function(response){
				self.comments = response.data.comments
			})
			.catch(error => console.log(error))
			.finally( () => {
			})

		},


		wordfilename( title ) {

		    var d = new Date(),
		        month = '' + (d.getMonth() + 1),
		        day = '' + d.getDate(),
		        year = d.getFullYear();

		    if (month.length < 2) 
		        month = '0' + month;
		    if (day.length < 2) 
		        day = '0' + day;

		    return [year, month, day].join('')+' '+title+'.docx';
		},


		exporttoword( object , callback ){

				bus.$emit('craeteaToast', {content:"Generating document for export",title:'Exporting', variant:'info',append:true, sup:''})

				//$('#moreMisc-1')[0].click()
				//this.exportingtoword = true

				var bodytext = this.newsletter.contents

				let self = this

				const newDiv = document.createElement("div");
			 	newDiv.innerHTML = bodytext

			 	let imgs = $('img', newDiv )

			 	imgs.each( function( key, thisimg ){

			 		if( thisimg.src.includes("s3-eu") ){

			 			var parent = $(thisimg).parents('.fr-img-space-wrap')
			 			var caption = $(thisimg).siblings('.fr-inner')
			 			var small = $('<p></p>')
			 			//var br = $('<br>')

			 			var img = new Image();
			 			thisimg.style = ""
			 			thisimg.width = thisimg.width * .75
			 			thisimg.align = "left"
			 			thisimg.removeAttribute("align")

			 			$(thisimg).insertBefore(parent)
			 			small.append(caption)
			 			small.insertAfter(thisimg)
			 			//$(br).insertAfter(small)

			 			$(parent).remove()

			 		}

			 	})			 	


			 	bodytext = newDiv.innerHTML

			 	//prompt( "", bodytext )

			 	let newfilename = this.wordfilename( self.newsletter.name )

			 	Newslettersapi.Docx( { template:this.newsletter.template, filename:newfilename, body:bodytext, t:this.token} ).then( function( response ){

			 		if( response.data?.ok ){

			 			self.exportedwordlocation = response.data.apidomain + '/static/exports/' +self.newsletter.template+ '/' + newfilename

			 			setTimeout( function(){

							location.href = self.exportedwordlocation

			 			},50)

			 		}else{

			 			self.exportingtoword = false
			 		}


			 	}).finally( function( response ){
			 		//self.exportingtoword = false
			 	})


			},


		opennewsletterforviewing( newsletterid ){

				
				this.newsletterid = newsletterid
				let self = this
				this.isshown = true
				Newslettersapi.Openforviewing( newsletterid, this.token, 'desktop', true )
				.then( function(response){

				if( response.data.ok && response.data.newsletter ){ 

					self.newsletter = response.data.newsletter
					setTimeout( function(){

						document.title =self.newsletter.name+' | Flowe'						
						self.loadComments( self.newsletter )

												

						let stage = document.getElementById('newslettereditorcard')

						if( self.devisetype=='mobil' ){	

						let tables = stage.querySelectorAll("table.reporttable"); 
						//console.log( tables )class="reporttable"

						tables.forEach( function( table ){

							var wrapper = document.createElement('div');
							wrapper.className = "table-responsive";

							table.parentNode.insertBefore(wrapper, table);
							wrapper.appendChild(table);

						});


						}


						let images = stage.querySelectorAll("img"); 
						//console.log( tables )

						images.forEach( function( img ){

							//console.log( img, "img")

							img.addEventListener("click", function( img ){

								self.attachviewer( img.target )
							});

							//

						});

						

					}, 100)			
					
					
						if(self.$route.nid != self.newsletter._id){
							self.$func.addQuery({nid:self.newsletter._id},self.$route, self.$router)
						}
						if(self.$route.page != 'newsletter'){
							self.$func.addQuery({page:'newsletter'},self.$route, self.$router)
						}

				}else{

					//fourofoured
					/*
					if( response.data.error && response.data.error.message == "nopermission" ){
						self.$router.push("/403")
					}else{
						self.$router.push("/400")
					}*/
					
				}

				}).finally(()=>{
		
				})

				Cockpitsapi.Cockpits( { "_id":this.cockpitid, "token": this.token } )
				.then( function(response){

					if( response.data.ok || !response.data.ok ){
					self.cockpit = response.data.cockpits
					}
				})
				.catch(error => console.log(error))

				.finally( () => {
					self.pagemode = 'ready'
				})


			},

			getobjectives(){


				let self = this
				
				this.cockpit = {}
				
				Cockpitsapi.Cockpits( { "_id":this.cockpitid, "token": this.token } )
				.then( function(response){

					//console.log( response.data, "cccc")

					if( response.data.ok || !response.data.ok ){
						
						self.cockpit = response.data.cockpits
						self.cockpitname = self.cockpit.name

						self.rendercurrentmilestones()
						self.renderfuturemilestones()
						self.renderriskstop()
						self.renderrisksclose()
						self.renderrisksother()

						self.renderstakeholders()
						
						if( self.viewer == true ){

						}else if(self.mode == "editor"){

							setTimeout( function(){

								self.inittabs()

							}, 200)
	

						}						

					}
				})
				.catch(error => console.log(error))

				.finally( () => {
					self.pagemode = 'ready'

				})

			},

			initpage(){

				let self = this

				this.stakeholdersmanage = []
				this.stakeholderssatisfied = []
				this.stakeholdersinformed = []
				this.stakeholdersmonitor = []


				//this.froalaconfig.events.contentChanged = function () {self.updatedraft(self) }
				this.periodStart = null
				this.periodend = null

				this.getobjectives()

			},


			loadComments(newsletter){

				let self = this 
				Cockpitsapi.Comments( { context:this.orgkey, "iid":newsletter._id, "type":"newsletter", "token": this.token} )
				.then( function(response){

					if( response.data.ok && response.data.comments != null){
						//console.log( response.data.comments)
						self.newsletter.comments = response.data.comments
						self.newsletter.commentscount = response.data.numcomments

					}

				})
				.catch(error => console.log(error))
				.finally( () => {
					//do everything
					self.loadingindicatordata.comments =false
					//self.loadLikes( indicator )
				})

			},

			loadLikes(indicator){

			let self = this 
			Cockpitsapi.Likes( { "iid":indicator._id, "type":"status", "token": this.token } )
			.then( function(response){

				if( response.data.ok ){
				indicator.likescount = response.data.numlikes
				indicator.dislikescount = response.data.numdislikes
				indicator.vote = response.data.hasvoted
			}

			})
			.catch(error => console.log(error))
			.finally( () => {
				self.loadingindicatordata.like = false
			//do everything
			})


			},
			showcomments(){
				let self = this
				this.showalways = this.showalways ? false : true


				setTimeout( function(){   			

	    			self.$refs.gotocomments.click()

					// $(window).scrollTop(200)
				}, 100)
				
				
				//this.loadComments( newsletter )
			},


			timeFormat : function( unixtime ){

				var date = new Date( unixtime * 1000 )

				var str = ""

				if( date != "" ){
					str = moment( date ).format('YYYY-MM-DD')
				}

				return str 
			},
			dateFormat : function( obj ){

				var str = ""

				if( obj != "" ){
					str = moment( obj ).format('YYYY-MM-DD')             
				}
				//str = moment( obj ).fromNow()


				return str 
			},

		}
	}

</script>